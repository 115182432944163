import {
  NOTICE_ACCESS,
  NOTICE_DETAILS_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/notice",
    name: "admin-notice",
    component: () => import("@/views/admin/notice/NoticeView.vue"),
    meta: {
      gate: NOTICE_ACCESS,
      requiresAuth: true,
      pageTitle: "Notice",
      homePatch: "/admin/notice",
      breadcrumb: [
        {
          text: "Notice",
          active: true,
        },
      ],
    },
  },

  {
    path: "/admin/notice/:id/details",
    name: "admin-notice-details",
    component: () => import("@/views/admin/notice/NoticeDetailsView.vue"),
    meta: {
      gate: NOTICE_DETAILS_ACCESS,
      requiresAuth: true,
      pageTitle: "Notice Details",
      homePatch: "/admin/notice",
      breadcrumb: [
        {
          text: "Notice",
          active: false,
          to: "/admin/notice",
        },
        {
          text: "Details",
          active: true,
        },
      ],
    },
  },
];
