import {
  USERS_ACCESS,
  USERS_PROFILE_ACCESS,
  USERS_PROFILE_DETAILS_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/employees",
    name: "admin-users",
    component: () => import("@/views/admin/users/UsersView.vue"),
    meta: {
      gate: USERS_ACCESS,
      requiresAuth: true,
      pageTitle: "Employees",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Employees",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/user/profile/:id",
    name: "user-profile",
    component: () =>
      import("@/views/admin/users/user-details/UserDetailsView.vue"),
    meta: {
      gate: USERS_PROFILE_ACCESS,
      requiresSuperAuth: true,
      requiresAuth: true,
      pageTitle: "Profile",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Employee",
          active: false,
          to: "/admin/employees",
        },
        {
          text: "Profile",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/users/edit/:id",
    name: "admin-user-edit-details",
    component: () =>
      import("@/views/admin/users/user-edit-details/UserEditDetailsView.vue"),
    meta: {
      gate: USERS_PROFILE_DETAILS_ACCESS,
      requiresAuth: true,
      pageTitle: "Profile Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Employee",
          active: false,
          to: "/admin/employees",
        },
        {
          text: "Profile",
          active: false,
          to: "/admin/user/profile/:id",
        },
        {
          text: "Details",
          active: true,
          to: "",
        },
      ],
    },
  },
];
