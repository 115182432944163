import {
  MILESTONE_BOARD_ACCESS,
  NOTICE_DETAILS_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/notification",
    name: "admin-notification",
    component: () => import("@/views/admin/notification/NotificationView.vue"),
    meta: {
      gate: MILESTONE_BOARD_ACCESS,
      requiresAuth: true,
      pageTitle: "Notification",
      homePatch: "/admin/notification",
      breadcrumb: [
        {
          text: "Notification",
          active: true,
        },
      ],
    },
  },
];
