export const leadModule = {
  namespaced: true,
  state: {
    //   user: null,
    //   permissions: null,
    filters: {
      filterSourceId: "",
      filterStatusId: "",
      filterEmpId: "",
      filterCategoryId: "",
      filterDepartmentId: "",
      filterCountryId: "",
      searchTerm: "",
      filterStartDate: "",
      filterEndDate: "",
    },
  },
  getters: {
    getFilters: function (state) {
      return state.filters;
    },
    getUser: function (state) {
      return state.user;
    },
    getPermissions: function (state) {
      return state.permissions;
    },
  },
  mutations: {
    setFilter: function (state, payload) {
      state.filters[payload.key] = payload.value;
    },
    removeUser: function (state, payload) {
      state.user = null;
    },
    setPermissions: function (state, payload) {
      state.permissions = payload.permissions;
    },
  },
  actions: {
    setUser: function (context, payload) {
      context.commit("setUser", { user: payload.user });
    },
    removeUser: function (context, payload) {
      context.commit("removeUser");
    },
    setPermissions: function (context, payload) {
      context.commit("setPermissions", {
        permissions: payload.permissions,
      });
    },

    setFilter: function (context, payload) {
      // Pass both key and value in the payload
      context.commit("setFilter", {
        key: payload.key,
        value: payload.value,
      });
    },
  },
};
