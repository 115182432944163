import {
  LOAN_ACCESS,
  LOAN_POLICY_ACCESS,
  LOAN_SHOW,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/loan",
    name: "admin-loan",
    component: () => import("@/views/admin/loan-management/loan/LoanView.vue"),
    meta: {
      gate: LOAN_ACCESS,
      requiresAuth: true,
      pageTitle: "Loan",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Loan",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/loan/:id",
    name: "admin-loan-details",
    component: () =>
      import("@/views/admin/loan-management/loan/LoanDetailsView.vue"),
    meta: {
      gate: LOAN_SHOW,
      requiresAuth: true,
      pageTitle: "Loan Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Loan Details",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/loan/loan-policies",
    name: "admin-loan-policies",
    component: () =>
      import("@/views/admin/loan-management/loan-policy/LoanPolicyView.vue"),
    meta: {
      gate: LOAN_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Loan Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Loan Policy",
          active: true,
          to: "",
        },
      ],
    },
  },
];
