import {
  SALARY_POLICY_ACCESS,
  PROJECT_COMMISSION_POLICY_ACCESS,
  OVER_TIME_POLICY_ACCESS,
  INSURANCE_POLICY_ACCESS,
  INCOME_TAX_POLICY_ACCESS,
  FESTIVAL_BONUS_POLICY_ACCESS,
  DELAY_FINE_POLICY_ACCESS,
  FINE_POLICY_ACCESS,
  MEAL_POLICY_ACCESS,
  SALARY_PAYSLIP_ACCESS,
  INCOME_TAX_POLICY_SHOW,
  SALARY_PAYSLIP_SHOW,
  PAYROLL_MANAGEMENT_ACCESS,
  EXTRA_LEAVE_POLICY_ACCESS,
  PROVIDENT_FUND_POLICY_ACCESS,
} from "@/helpers/permissionsConstant";
export default [
  {
    path: "/admin/payroll/policy/delay-fine-policies",
    name: "admin-delay-fine-policies",
    component: () =>
      import(
        "@/views/admin/payroll-management/delay-fine-policy/DelayFinePolicyView.vue"
      ),
    meta: {
      gate: DELAY_FINE_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Late Fine Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Delay Fine Policy",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/payroll/salary-policy",
    name: "admin-salary-policy",
    component: () =>
      import(
        "@/views/admin/payroll-management/salary-policy/SalaryPolicyView.vue"
      ),
    meta: {
      gate: SALARY_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Salary Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Salary Policy",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/policy/project-commission-policies",
    name: "admin-project-commission-policies",
    component: () =>
      import(
        "@/views/admin/payroll-management/project-commission-policy/ProjectCommissionPolicyView.vue"
      ),
    meta: {
      gate: PROJECT_COMMISSION_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Project Commission Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Project Commission Policy",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/policy/meal-policies",
    name: "admin-meal-policies",
    component: () =>
      import("@/views/admin/payroll-management/meal-policy/MealPolicyView.vue"),
    meta: {
      gate: MEAL_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Meal Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Meal Policy",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/policy/insurance-policies",
    name: "admin-insurance-policy",
    component: () =>
      import(
        "@/views/admin/payroll-management/insurance-policy/InsurancePolicyView.vue"
      ),
    meta: {
      gate: INSURANCE_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Insurance Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Insurance Policy",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/payroll/policy/provident-fund-policy",
    name: "admin-provident-fund-policy",
    component: () =>
      import(
        "@/views/admin/payroll-management/provident-fund/ProvidentFundPolicyView.vue"
      ),
    meta: {
      gate: PROVIDENT_FUND_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Provident Fund",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Provident Fund",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/payroll/policy/fine-policies",
    name: "admin-fine-policies",
    component: () =>
      import("@/views/admin/payroll-management/fine-policy/FinePolicyView.vue"),
    meta: {
      gate: FINE_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Fine Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Fine Policy",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/policy/festival-bonus-policies",
    name: "admin-festival-bonus-policies",
    component: () =>
      import(
        "@/views/admin/payroll-management/festival-bonus-policy/FestivalBonusPolicyView.vue"
      ),
    meta: {
      gate: FESTIVAL_BONUS_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Festival Bonus Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Festival Bonus Policy",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/policy/extra-leave-policy",
    name: "admin-extra-leave-policy",
    component: () =>
      import(
        "@/views/admin/payroll-management/extra-leave-policy/ExtraLeavePolicyView.vue"
      ),
    meta: {
      gate: EXTRA_LEAVE_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Extra Leave Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Extra Leave Policy",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/over-time-policy",
    name: "admin-over-time-policy",
    component: () =>
      import(
        "@/views/admin/payroll-management/overtime-policy/OverTimePolicyView.vue"
      ),
    meta: {
      gate: OVER_TIME_POLICY_ACCESS,
      requiresAuth: true,
      pageTitle: "Over Time Policy",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "OverTime Policy",
          active: true,
          to: "",
        },
      ],
    },
  },

  // {
  //   path: "/admin/payroll/income-tax-policy",
  //   name: "admin-income-tax-policy",
  //   component: () =>
  //     import(
  //       "@/views/admin/payroll-management/income-tax-policy/IncomeTaxPolicyView.vue"
  //     ),
  //   meta: {
  //     gate: INCOME_TAX_POLICY_ACCESS,
  //     requiresAuth: true,
  //     pageTitle: "Income Tax Policy",
  //     homePatch: "/admin/dashboard",
  //     breadcrumb: [
  //       {
  //         text: "Income Tax Policy",
  //         active: true,
  //         to: "",
  //       },
  //     ],
  //   },
  // },

  {
    path: "/admin/payroll/income-tax-policy/:id/income-tax-policy-details",
    name: "admin-income-tax-policy-details",
    component: () =>
      import(
        "@/views/admin/payroll-management/income-tax-policy-details/IncomeTaxPolicyDetailsView.vue"
      ),
    meta: {
      gate: INCOME_TAX_POLICY_SHOW,
      requiresAuth: true,
      pageTitle: "Income Tax Policy Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Income Tax Policy Details",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/:policyCategoryName/:policyCategoryId/policy/:id/assign-details",
    name: "admin-payroll-policy-assign-details",
    component: () =>
      import(
        "@/views/admin/payroll-management/payroll-policy-assign-user/PayrollPolicyAssignUserDetailsView.vue"
      ),
    meta: {
      gate: PAYROLL_MANAGEMENT_ACCESS,
      requiresAuth: true,
      pageTitle: "Policy Details ",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Policy Details ",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/payroll/payslip",
    name: "admin-salary-payslip",
    component: () =>
      import("@/views/admin/payroll-management/payslip/PayslipView.vue"),
    meta: {
      gate: SALARY_PAYSLIP_ACCESS,
      requiresAuth: true,
      pageTitle: "Salary Payslip",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Payslip",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/payroll/user/:userId/payslip/:id/details",
    name: "admin-salary-payslip-details",
    component: () =>
      import("@/views/admin/payroll-management/payslip/DevPayslipDetailsView.vue"),
    meta: {
      gate: SALARY_PAYSLIP_SHOW,
      requiresAuth: true,
      pageTitle: "Salary Payslip Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Payslip",
          active: false,
          to: "/admin/payroll/payslip",
        },
        {
          text: "Details",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/payroll/user/:userId/payslip/:id/details/dev",
    name: "admin-salary-payslip-details-dev",
    component: () =>
      import(
        "@/views/admin/payroll-management/payslip/DevPayslipDetailsView.vue"
      ),
    meta: {
      gate: SALARY_PAYSLIP_SHOW,
      requiresAuth: true,
      pageTitle: "Salary Payslip Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Payslip",
          active: false,
          to: "/admin/payroll/payslip",
        },
        {
          text: "Details",
          active: true,
          to: "",
        },
      ],
    },
  },
];
