import { DIVISION_ACCESS } from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/division",
    name: "admin-division",
    component: () => import("@/views/admin/division/DivisionView.vue"),
    meta: {
      gate: DIVISION_ACCESS,
      requiresAuth: true,
      pageTitle: "Division",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Division",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/division/trash",
    name: "admin-division-trash",
    component: () => import("@/views/admin/division/DivisionTrashView.vue"),
    meta: {
      gate: DIVISION_ACCESS,
      requiresAuth: true,
      pageTitle: "Division Archives",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Division",
          active: false,
          to: "/admin/division",
        },
        {
          text: "Division Archives",
          active: true,
          to: "n",
        },
      ],
    },
  },
];
