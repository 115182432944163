import {
  SETTINGS_ACCESS,
  WEEKENDS_ACCESS,
  HOLIDAYS_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/settings/general",
    name: "admin-settings-general",
    component: () => import("@/views/admin/settings/GeneralSettingView.vue"),
    meta: {
      gate: SETTINGS_ACCESS,
      requiresAuth: true,
      pageTitle: "General Settings",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "General Settings",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/settings/weekends",
    name: "admin-settings-weekends",
    component: () => import("@/views/admin/settings/weekends/WeekendsView.vue"),
    meta: {
      gate: WEEKENDS_ACCESS,
      requiresAuth: true,
      pageTitle: "Weekends Settings",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Weekends Settings",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/settings/weekends/calender",
    name: "admin-settings-weekends-calender",
    component: () =>
      import("@/views/admin/settings/weekends/WeekendsCalenderView.vue"),
    meta: {
      gate: HOLIDAYS_ACCESS,
      requiresAuth: true,
      pageTitle: "Weekends Calender",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Weekends Settings",
          active: false,
          to: "/admin/settings/weekends",
        },
        {
          text: "Weekends Calender",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/settings/holidays",
    name: "admin-settings-holidays",
    component: () => import("@/views/admin/settings/holidays/HolidaysView.vue"),
    meta: {
      gate: HOLIDAYS_ACCESS,
      requiresAuth: true,
      pageTitle: "Holidays Settings",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Holidays Settings",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/settings/holidays/calender",
    name: "admin-settings-holidays-calender",
    component: () =>
      import("@/views/admin/settings/holidays/HolidaysCalenderView.vue"),
    meta: {
      gate: HOLIDAYS_ACCESS,
      requiresAuth: true,
      pageTitle: "Holidays Calender",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Holidays Settings",
          active: false,
          to: "/admin/settings/holidays",
        },
        {
          text: "Holidays Calender",
          active: true,
          to: "",
        },
      ],
    },
  },
];
