import {
  LEAVE_TYPES_ACCESS,
  LEAVE_REQUESTS_ACCESS,
  LEAVE_APPROVE_ACCEPT, LEAVE_BALANCE_ACCESS,
  LEAVE_APPROVE_DEPT_HEAD_ACCEPT, LEAVE_APPROVE_DIVISION_HEAD_ACCEPT
} from '@/helpers/permissionsConstant'

export default [
  {
    path: "/admin/leave/types",
    name: "admin-leave-types",
    component: () => import("@/views/admin/leave/LeaveTypesView.vue"),
    meta: {
      gate: LEAVE_TYPES_ACCESS,
      requiresAuth: true,
      pageTitle: "Leave Types",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Leave Types",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/leave/requests",
    name: "admin-leave-requests",
    component: () => import("@/views/admin/leave/LeaveRequestsView.vue"),
    meta: {
      gate: LEAVE_REQUESTS_ACCESS,
      requiresAuth: true,
      pageTitle: "Apply Leave",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Apply Leave",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/leave/approve",
    name: "admin-leave-approve",
    component: () => import("@/views/admin/leave/LeaveApproveView.vue"),
    meta: {
      gate: [LEAVE_APPROVE_DEPT_HEAD_ACCEPT, LEAVE_APPROVE_ACCEPT, LEAVE_APPROVE_DIVISION_HEAD_ACCEPT],
      requiresAuth: true,
      pageTitle: "Leave Requests",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Leave Request",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/leave/balance",
    name: "leave-balance",
    component: () => import("@/views/admin/leave/LeaveBalanceView.vue"),
    meta: {
      gate: LEAVE_BALANCE_ACCESS,
      requiresAuth: true,
      pageTitle: "Leave Balance",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Leave Balance",
          active: true,
          to: "",
        },
      ],
    },
  }
];
