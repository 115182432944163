import { DESIGNATION_ACCESS } from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/designation",
    name: "admin-designation",
    component: () => import("@/views/admin/designation/DesignationView.vue"),
    meta: {
      gate: DESIGNATION_ACCESS,
      requiresAuth: true,
      pageTitle: "Designation",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Designation",
          active: true,
          to: "",
        },
      ],
    },
  },
];
