import {
  DEPARTMENT_ACCESS,
  DEPARTMENT_SHOW,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/department",
    name: "admin-department",
    component: () => import("@/views/admin/department/DepartmentView.vue"),
    meta: {
      gate: DEPARTMENT_ACCESS,
      requiresAuth: true,
      pageTitle: "Department",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Department",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/department/:id",
    name: "admin-department-details",
    component: () =>
      import("@/views/admin/department/DepartmentDetailsView.vue"),
    meta: {
      gate: DEPARTMENT_SHOW,
      requiresAuth: true,
      pageTitle: "Department Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Department",
          active: false,
          to: "/admin/department",
        },
        {
          text: "Department Details",
          active: true,
          to: "",
        },
      ],
    },
  },
];
