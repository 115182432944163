import SecureLS from "secure-ls"
import moment from 'moment'
import appStore from '@/store/app'
import { computed } from '@vue/composition-api'
import store from '@/store'

export const ls = new SecureLS({
  encodingType: process.env.VUE_APP_ENCODING_TYPE,
  isCompression: true,
  encryptionSecret: process.env.VUE_APP_ENCRYPTION_SECRET,
  encryptionNamespace: process.env.VUE_APP_ENCRYPTION_NAMESPACE,
})

// has permission
export const permissionAbility = (gates, permissions) => {
  if (!Array.isArray(gates)) {
    // If gates is not an array, convert it to an array with a single element
    gates = [gates];
  }

  return gates.some(gate => (permissions || []).includes(gate));
};

export const formatDate = value => {
  if (value) {
    const globalDateFormat = computed(() => store.getters['app/getGlobalDateFormat'])
    return moment(value).format(globalDateFormat.value)
  }
  return 'Not Set'
}

// minutes to hours converter
export const minutesToHours = (minutes) => {
  if (!minutes) return "00:00"; // If minutes is not provided, return "00:00"

  const sign = minutes < 0 ? "- " : ""; // Determine the sign
  minutes = Math.abs(minutes); // Make minutes positive
  const hours = Math.floor(minutes / 60);
  minutes %= 60;

  // Format the result as "hh:mm" and pad the hours and minutes with leading zeros if needed
  return (
    sign +
    `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
  );
};

/**
 * Formats a date range string into an object with startDate and endDate properties.
 *
 * @param {string} dateRange - The date range string in the format "YYYY-MM-DD to YYYY-MM-DD" or "YYYY-MM-DD"
 * @returns {Object} An object with startDate and endDate properties
 */
export const formatDateRange = dateRange => {
  if (!dateRange) return { startDate: '', endDate: '' }

  const dates = dateRange.split(' to ')

  if (dates.length === 1) {
    return { startDate: dates[0], endDate: dates[0] }
  }

  if (dates.length !== 2) {
    console.error('Invalid date range format')
    return { startDate: '', endDate: '' } // or throw an error, depending on your requirements
  }

  return { startDate: dates[0], endDate: dates[1] }
};
