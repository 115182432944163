import {
  EMPLOYEE_TYPES_ACCESS,
  EMPLOYEE_TYPES_TRASH_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/employee/types",
    name: "admin-employee-types",
    component: () => import("@/views/admin/employee/EmployeeTypesView.vue"),
    meta: {
      gate: EMPLOYEE_TYPES_ACCESS,
      requiresAuth: true,
      pageTitle: "Employee Types",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Employee Types",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/employee-types/archive",
    name: "employee-types-trash",
    component: () =>
      import("@/views/admin/employee/EmployeeTypesTrashedView.vue"),
    meta: {
      gate: EMPLOYEE_TYPES_TRASH_ACCESS,
      requiresAuth: true,
      pageTitle: "Employee Types Archive",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Employee Types",
          active: false,
          to: "/admin/types",
        },
        {
          text: "Employee Types Archive",
          active: true,
          to: "n",
        },
      ],
    },
  },
];
