import { ASSET_TYPE_ACCESS, ASSET_ACCESS } from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/asset/categories",
    name: "admin-asset-categories",
    component: () =>
      import("@/views/admin/asset-management/AssetCategoryView.vue"),
    meta: {
      gate: ASSET_TYPE_ACCESS,
      requiresAuth: true,
      pageTitle: "Categories",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Asset Categories",
          active: true,
        },
      ],
    },
  },

  {
    path: "/admin/assets",
    name: "admin-assets",
    component: () => import("@/views/admin/asset-management/AssetView.vue"),
    meta: {
      gate: ASSET_ACCESS,
      requiresAuth: true,
      pageTitle: "Asset",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Asset",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/assets/details/:id",
    name: "admin-assets-details",
    component: () =>
      import("@/views/admin/asset-management/AssetDetailsView.vue"),
    meta: {
      gate: ASSET_ACCESS,
      requiresAuth: true,
      pageTitle: "Asset Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Asset",
          active: false,
          to: "/admin/assets",
        },
        {
          text: "Asset Details",
          active: true,
        },
      ],
    },
  },
];
