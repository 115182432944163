let pusherChannel = "";
let pusherAppKey = "";
if (process.env.VUE_APP_ENCRYPTION_NAMESPACE === "vuex-my-prod") {
  pusherChannel = "project-x-development";
  pusherAppKey = "a036d9769371edd93518";
} else if (
  process.env.VUE_APP_ENCRYPTION_NAMESPACE === "vuex-projectx-prod-dev"
) {
  pusherChannel = "project-x-staging";
  pusherAppKey = "249e904cd0cef593d76e";
} else if (
  process.env.VUE_APP_ENCRYPTION_NAMESPACE === "vuex-projectx-prod-zone"
) {
  pusherChannel = "project-x-production";
  pusherAppKey = "5372a094f7b38d17ecbb";
}

export { pusherChannel, pusherAppKey };
