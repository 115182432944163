import {
  PROJECT_ACCESS,
  PROJECT_SHOW,
  MILESTONE_ACCESS,
  INVOICE_CREATE,
  EXPENSE_CREATE,
  PAYSLIP_ACCESS,
  ACCOUNT_TYPE_ACCESS,
  ACCOUNT_ACCESS,
  ACCOUNT_SHOW,
  ACCOUNT_TRANSFER_ACCESS,
  ACCOUNT_DEPOSIT_ACCESS,
  TRANSACTION_HISTORIES_ACCESS,
} from "@/helpers/permissionsConstant";

export default [
  {
    path: "/admin/projects",
    name: "admin-projects",
    component: () => import("@/views/admin/finance/project/ProjectsView.vue"),
    meta: {
      gate: PROJECT_ACCESS,
      requiresAuth: true,
      pageTitle: "Projects",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Projects",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/account/types",
    name: "admin-account-types",
    component: () =>
      import("@/views/admin/finance/account/AccountTypeView.vue"),
    meta: {
      gate: ACCOUNT_TYPE_ACCESS,
      requiresAuth: true,
      pageTitle: "Account Types",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Account Types",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/accounts",
    name: "admin-accounts",
    component: () => import("@/views/admin/finance/account/AccountsView.vue"),
    meta: {
      gate: ACCOUNT_ACCESS,
      requiresAuth: true,
      pageTitle: "Accounts",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Accounts",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/accounts/:id",
    name: "admin-accounts-details",
    component: () =>
      import("@/views/admin/finance/account/AccountDetailsView.vue"),
    meta: {
      gate: ACCOUNT_SHOW,
      requiresAuth: true,
      pageTitle: "Account Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Accounts",
          active: false,
          to: "/admin/accounts",
        },
        {
          text: "Account Details",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/account/transfer",
    name: "admin-account-transfer",
    component: () =>
      import("@/views/admin/finance/account-transfer/AccountTransferView.vue"),
    meta: {
      gate: ACCOUNT_TRANSFER_ACCESS,
      requiresAuth: true,
      pageTitle: "Account Transfer",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Account Transfer",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/account/deposit",
    name: "admin-account-deposit",
    component: () =>
      import("@/views/admin/finance/account-deposit/AccountDepositView.vue"),
    meta: {
      gate: ACCOUNT_DEPOSIT_ACCESS,
      requiresAuth: true,
      pageTitle: "Account Deposit",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Account Deposit",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/project/:id",
    name: "admin-project-details",
    component: () =>
      import("@/views/admin/finance/project/ProjectDetailsView.vue"),
    meta: {
      gate: PROJECT_SHOW,
      requiresAuth: true,
      pageTitle: "Project Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Projects",
          active: false,
          to: "/admin/projects",
        },
        {
          text: "Project Details",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/milestones",
    name: "admin-milestones",
    component: () =>
      import("@/views/admin/finance/milestone/MilestonesView.vue"),
    meta: {
      gate: MILESTONE_ACCESS,
      requiresAuth: true,
      pageTitle: "Milestones",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Milestones",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/milestone/invoice/:id",
    name: "admin-milestone-invoice",
    component: () => import("@/views/admin/finance/invoice/InvoiceView.vue"),
    meta: {
      gate: INVOICE_CREATE,
      requiresAuth: true,
      pageTitle: "Invoice",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Milestones",
          active: false,
          to: "/admin/milestones",
        },
        {
          text: "Invoice",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/expense",
    name: "expense",
    component: () => import("@/views/admin/finance/expense/ExpenseView.vue"),
    meta: {
      gate: EXPENSE_CREATE,
      requiresAuth: true,
      pageTitle: "Expense",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Expenses",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/payslip/:id",
    name: "admin-payslip",
    component: () => import("@/views/admin/finance/payslip/PayslipView.vue"),
    meta: {
      gate: PAYSLIP_ACCESS,
      requiresAuth: true,
      pageTitle: "Payslip",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Expense",
          active: false,
          to: "/admin/expense",
        },
      ],
    },
  },
  {
    path: "/admin/transaction/histories",
    name: "admin-transaction-histories",
    component: () =>
      import("@/views/admin/finance/transaction/TransactionHistoryView.vue"),
    meta: {
      gate: TRANSACTION_HISTORIES_ACCESS,
      requiresAuth: true,
      pageTitle: "Transaction Histories",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Transaction Histories",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/milestone/:id/invoice",
    name: "admin-invoice-details",
    component: () =>
      import("@/views/admin/finance/invoice/InvoiceCreateView.vue"),
    meta: {
      gate: MILESTONE_ACCESS,
      requiresAuth: true,
      pageTitle: "Invoice",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Milestones",
          active: false,
          to: "/admin/milestones",
        },
      ],
    },
  },

  {
    path: "/admin/invoices",
    name: "admin-invoices",
    component: () => import("@/views/admin/finance/invoice/InvoicesView.vue"),
    meta: {
      gate: MILESTONE_ACCESS,
      requiresAuth: true,
      pageTitle: "Invoice",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Invoices",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/milestone/:milestoneId/invoice/:id",
    name: "admin-invoice-edit-details",
    component: () =>
      import("@/views/admin/finance/invoice/InvoiceDetailsView.vue"),
    meta: {
      gate: MILESTONE_ACCESS,
      requiresAuth: true,
      pageTitle: "Invoice",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Milestones",
          active: false,
          to: "/admin/milestones",
        },
      ],
    },
  },
];
