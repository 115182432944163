export const invoiceModule = {
  namespaced: true,
  state: {
    filters: {
      id: "",
      searchTerm: "",

      // filterStatusId: "",
      // filterEmpId: "",
      // filterCategoryId: "",
      // filterDepartmentId: "",
      // filterCountryId: "",
      // searchTerm: "",
      // filterStartDate: "",
      // filterEndDate: "",
    },
  },
  getters: {
    getFilters: function (state) {
      return state.filters;
    },
  },
  mutations: {
    setFilter: function (state, payload) {
      state.filters[payload.key] = payload.value;
    },
  },
  actions: {
    setFilter: function (context, payload) {
      // Pass both key and value in the payload
      context.commit("setFilter", {
        key: payload.key,
        value: payload.value,
      });
    },
  },
};
