import {
  LEAD_CATEGORY_ACCESS,
  LEAD_SOURCE_ACCESS,
  LEAD_STATUS_ACCESS,
  LEAD_DETAILS_ACCESS,
  LEAD_ACTIVITY_ACCESS,
  MY_LEAD_ACCESS,
  LEAD_ACCESS,
} from "@/helpers/permissionsConstant";
export default [
  {
    path: "/admin/lead/categories",
    name: "admin-lead-categories",
    component: () => import("@/views/admin/lead/LeadCategoryView.vue"),
    meta: {
      gate: LEAD_CATEGORY_ACCESS,
      requiresAuth: true,
      pageTitle: "Categories",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Categories",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/lead/sources",
    name: "admin-lead-sources",
    component: () => import("@/views/admin/lead/LeadSourceView.vue"),
    meta: {
      gate: LEAD_SOURCE_ACCESS,
      requiresAuth: true,
      pageTitle: "Sources",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Sources",
          active: true,
          to: "",
        },
      ],
    },
  },

  {
    path: "/admin/lead/status",
    name: "admin-lead-status",
    component: () => import("@/views/admin/lead/LeadStatusView.vue"),
    meta: {
      gate: LEAD_STATUS_ACCESS,
      requiresAuth: true,
      pageTitle: "Status",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Status",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/lead/management",
    name: "admin-lead-management",
    component: () => import("@/views/admin/lead/LeadView.vue"),
    meta: {
      gate: LEAD_ACCESS,
      requiresAuth: true,
      pageTitle: "All Leads",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "All Leads",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/lead/my-lead-management",
    name: "my-lead-management",
    component: () => import("@/views/admin/lead/MyLeadView.vue"),
    meta: {
      gate: MY_LEAD_ACCESS,
      requiresAuth: true,
      pageTitle: "My Leads",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "My Leads",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/admin/lead/:id",
    name: "admin-lead-details",
    component: () => import("@/views/admin/lead/LeadDetailsView.vue"),
    meta: {
      gate: LEAD_ACTIVITY_ACCESS,
      requiresAuth: true,
      pageTitle: "Lead Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Leads",
          active: false,
          to: "/admin/lead/management",
        },
        {
          text: "Lead Details",
          active: true,
          to: "",
        },
      ],
    },
  },
];
